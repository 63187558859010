<template>
  <!-- <div class="top"> -->
    <div class="mainHeader">
      <ul>
        <img src="@/assets/images/logo.png" />
        <div class="menus">
          <li>
            <a href="javascript:;">首页</a>
          </li>
          <li>
            <a href="javascript:;">产品介绍</a>
          </li>
          <li>
            <a href="javascript:;">公司介绍</a>
          </li>
          <li>
            <a href="javascript:;">成功案例</a>
          </li>
          <li>
            <a href="javascript:;">联系我们</a>
          </li>
        </div>
      </ul>
    <!-- </div> -->
  </div>
</template>

<script>
export default {
  name: 'mainHeader'
}
</script>

<style scoped>
.mainHeader {
  overflow-y: visible;
  box-sizing: border-box;
  width: calc(1190 * var(--groot-ui-rpx));
  height: calc(90 * var(--groot-ui-rpx));
  margin: 0;
  /* position: relative; */
  /*position: relative;*/ 
}
.menus {
  width: 500px;
  height: 80px;
  margin-right: 200px;
  float: right;
}
.mainHeader img {
  width: 200px;
  height: 60px;
  margin-top: 10px;
  margin-left: 20px;
  float: left;
}
.mainHeader a {
  text-decoration: none;
  display: block;
  color: #000000;
  height: 40px;
  line-height: 40px;
  border: solid 1px #fff;
  border-width: 1px 1px 0 0;
  background: #fff;
  margin-top: 30px;
}
ul{
  list-style-type: none;
  overflow: hidden;
  background-color: #ffffff;
  position: fixed;
  top: 0px;
  width: 100%;
  height: 80px;
  margin: 0px 0px 0px 0px;
}  
li {
  width: 20%;
  list-style-type: none;
  float: left;
  text-align: center;
  position: relative;
}
li a:hover {
  color: #fff;
  background: #ffb100;
}
</style>
