<template>
  <!-- <img alt="Vue logo" src="./assets/logo.png">
  <HelloWorld msg="Welcome to Your Vue.js App"/> -->
  <div class="main-top">
      <Header></Header>
      <ScrollPic></ScrollPic>
      <BigData></BigData>
      <Bottom></Bottom>
  </div>
</template>

<script>
import Header from '@/components/MainHeader'
import ScrollPic from '@/components/HomeScrollPic'
import BigData from '@/components/MainBigData'
import Bottom from '@/components/MainBottom'

export default {
  name: 'App',
  components: {
    Header,
    ScrollPic,
    BigData,
    Bottom
  }
}
</script>

<style>

/* .head-top {
  height: 80px;
  width: 100%;
  position: fixed;
  top: 5px;
}
.head-scroll-pic {
  position: relative;
} */

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0px;
  min-width: 1280px;
}
</style>
