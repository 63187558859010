<template>
    <div class="bigData">
		<div class="mainTitle">
			<p>产品介绍</p>
		</div>
        <div class="productBox">
            <div class="productTitle">
                <p>大数据分析平台</p>
            </div>
            <div class="productContent">
				<img src="@/assets/images/product1.jpg" />
                <p>      大数据分析平台是一款面向矿山大数据管理与分析的专业化分析平台，集数据采集、数据处理、数据挖掘、数据可视化、数据应用于一体。该产品是为矿山及采矿企业用户提供的专业数据探索与分析工具。</p>
            </div>
        </div>
        <div class="productBox">
            <div class="productTitle">
                <p>矿山数智化监控系统</p>
            </div>
            <div class="productContent">
				<img src="@/assets/images/product2.jpg" />
                <p>矿山数智化监控系统涵盖了空压机在线监控系统、皮带机在线监控系统、提升机在线监控等一系列安全监控系统。监控系统通过以太网模块接口来实现现场数据的远程传输，以太网模块接口采用RJ45标准接口。485接口用来连接触摸屏和电量采集模块。监控系统预留以太网接口，以实现远程数据交换，支持标准的TCP/IP协议。</p>
            </div>
        </div>
        <div class="productBox">
            <div class="productTitle">
                <p>智慧矿山智能设计系统</p>
            </div>
            <div class="productContent">
				<img src="@/assets/images/product3.jpg" />
                <p>智慧矿山智能设计系统应用现代信息技术，依据多年矿山行业设计经验，预制各种设计方案及算法，提高矿山设计的智能水平，从而使计算机能够更多、更好地承担设计过程中各种复杂任务，并对设计方案进行计算机模拟测试，协助设计人员对设计方案、算法及设计参数进行优化调整。</p>
            </div>
        </div>
        <div class="productBox">
            <div class="productTitle">
                <p>智慧矿山生产管理系统</p>
            </div>
            <div class="productContent">
				<img src="@/assets/images/product4.jpg" />
                <p>智慧矿山生产管理系统集生产管理、数据采集和监控等多种功能于一体，系统操作简单易用，管理者可以轻松掌握矿山生产过程中各种数据及设备运行情况，系统基于多年矿山生产管理经验打造，为矿山各级管理人员及时准确地提供管理和决策所需的各类信息。</p>
            </div>
        </div>

        <!-- <div class="top_place">
			<div class="main_tit">
				<p>应用场景</p>
				<div></div>
			</div>
			<div class="place_bot">
				<div>
					<dl>
						<dt>
							<img src="@/assets/images/seeable1.png">
						</dt>
						<dd>政务数据</dd>
					</dl>
					<dl>
						<dt>
							<img src="@/assets/images/seeable2.png">
						</dt>
						<dd>交通检测</dd>
					</dl>
					<dl>
						<dt>
							<img src="@/assets/images/seeable3.png">
						</dt>
						<dd>景区检测</dd>
					</dl>
					<dl>
						<dt>
							<img src="@/assets/images/seeable4.png">
						</dt>
						<dd>运营业务分析</dd>
					</dl>
				</div>
				<div>
					<dl>
						<dt>
							<img src="@/assets/images/seeable5.png">
						</dt>
						<dd>金融数据</dd>
					</dl>
					<dl>
						<dt>
							<img src="@/assets/images/seeable6.png">
						</dt>
						<dd>公共安全</dd>
					</dl>
					<dl>
						<dt>
							<img src="@/assets/images/seeable7.png">
						</dt>
						<dd>广告营销</dd>
					</dl>
					<dl>
						<dt>
							<img src="@/assets/images/seeable8.png">
						</dt>
						<dd>园区监控</dd>
					</dl>
				</div>
			</div>
		</div> -->
    </div>

</template>
<script>
export default {
  name: 'bigData',
  data() {
    return {}
  },
  methods: {
  }
}
</script>
<style scoped>
.bigData {
    width: 100%;
    /* height: 500px; */
    margin: 0 auto;
    margin-top: 0px;
    text-align: center;
    /* border-top: 1px solid #555555; */
    line-height: 18px;
    color: rgb(85, 85, 85);
    margin-top: 0px;
    padding-bottom: 10px;
    padding-top: 8px;
}
.mainTitle {
	width: 100%;
	margin: 0 auto;
	margin-top: 10px;
}
.mainTitle > p {
	text-align: center;
	font-size: 40px;
	font-family: 微软雅黑;
	font-weight: 500;
	color: #19191a;
}
.productTitle {
	width: 100%;
	margin: 0 auto;
	margin-top: 10px;
}
.productTitle > p {
	text-align: left;
	font-size: 32px;
	font-family: 微软雅黑;
	font-weight: 500;
	color: #19191a;
    margin-left: 10%;
}
.productContent {
	width: 80%;
	height:300px;
	margin: 0 auto;
	margin-top: 10px;
	border-top: 3px solid #3d29f6;
}
.productContent > p {
	text-align: left;
	text-indent: 2em;
	font-size: 24px;
	font-family: 微软雅黑;
	font-weight: 400;
	line-height: 40px;
	color: #19191a;
    margin-top: 30px;
    margin-left: 440px;
}
.productContent img {
	width: 427px;
	height: 240px;
	margin-top: 30px;
	margin-left: 0%;
	float: left;
}

/* .desc_box {
    box-sizing: border-box;
    font-family: '微软雅黑';
}
.desc_box > div:nth-child(2) p {
  font-size: 16px;
  color: #666;
  font-weight: 300;
  width: 917px;
  margin: 0 auto;
  line-height: 28px;
  text-align: center;
  padding-top: 34px;
}
.main_tit {
  width: 150px;
  margin: 0 auto;
    margin-top: 0px;
  margin-top: 142px;
}
.place_bot {
  padding-bottom: 97px;
  width: 935px;
  margin: 0 auto;
}
.place_bot > div {
  width: 935px;
  height: 173px;
}
.place_bot > div dl {
  float: left;
  margin-left: 175px;
}
img, embed, object, audio, video {
  height: auto;
  max-width: 100%;
}
img {
  border: none;
} */
</style>